import React from "react"
import Layout from "../layout/layout"
import ReactHtmlParser from "react-html-parser"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import HomestayFAQAccordion from "../components/Accordions/HomestayFAQ"
import GuardianshipVideo from "../video/Guardianship.mp4"
import Video from "../components/Video/LargeVideo"
import ButtonCardItem from "../components/ButtonCards/NewTabButton"
import { CardGrid, CardWrapper } from "../components/ButtonCards/CardElements"
import Logo from "../images/logo.png"
import { homestayBanner, homestayGif } from "../images/"
import {
  HomestayTestimonialCarousel,
  InsertedTestimony,
} from "../components/Testimonials"
import { CentreWrapper, GreenButton } from "../components/ButtonElement"
import SEO from "../components/seo"

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const ApplicationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const sidebar = [
  {
    to: "homestayFamily",
    title: "家庭寄宿",
  },
  {
    to: "fees",
    title: "费用",
  },
  {
    to: "faq",
    title: "常见问题",
  },
]

const GifContainer = styled.img`
  width: 100%;
  height: auto;
  align-self: center;

  @media (min-width: 2000px) {
    width: 1600px;
  }
`

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br />
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function Homestay({ data }) {
  const html = data.allWpPage.edges
  const fees = ReactHtmlParser(html[2].node.content)
  const homestayFamily = ReactHtmlParser(html[1].node.content)
  return (
    <div>
      <Layout
        sidebar={sidebar}
        pageTitle="家庭寄宿"
        background={homestayBanner}
        pageDescription="像对待家庭成员一样呵护学生，让他们体验真正的英式生活"
      >
        <SEO
          title="Homestay | UK Education | International Students | Support"
          description="Study Links provides a British Homestay experience where students feel part of the family. They are carefully selected to care for and support our students during their stay."
        />
        <Section id="homestayFamily">
          <FadeH1>住在寄宿家庭</FadeH1>
          {homestayFamily.map((data, index) => {
            return (
              <Fade>
                <div className="storyData">{data}</div>
              </Fade>
            )
          })}
          <GifContainer src={homestayGif} />
        </Section>
        <Section id="fees">
          <FadeH1>费用</FadeH1>
          {fees.map((data, index) => {
            return (
              <Fade>
                <div className="storyData">{data}</div>
              </Fade>
            )
          })}
          <Fade>
            <CentreWrapper>
              <GreenButton href="/contact">联系我们</GreenButton>
            </CentreWrapper>
            <InsertedTestimony
              author="Michelle Cheung - 学生"
              quote="我和寄宿家庭相处得很好，他们把我当作他们的女儿一样看待。他们让我觉得自己是他们家庭的一员，我真的很喜欢和他们在一起。"
            />
          </Fade>
        </Section>
        <Section id="faq">
          <FadeH1>常见问题</FadeH1>
          <HomestayFAQAccordion />
        </Section>
        <HomestayTestimonialCarousel />
      </Layout>
    </div>
  )
}

export const STUDYLINKS_HOMESTAY = graphql`
  query studylinks_homestay {
    allWpPage(
      filter: {
        title: {
          in: [
            "api-studylinks-simplified-homestayfees"
            "api-studylinks-simplified-homestay"
            "api-studylinks-simplified-homestayfamily"
          ]
        }
      }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
