import React from "react"

const HomestayFAQAccordion = () => {
  return (
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button
              class="btn btn-link"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              什么是家庭寄宿？
            </button>
          </h5>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            他们是由 Study Links
            精心挑选出来的家庭，负责照顾您的孩子，确保他们放学后有一个放松和安全的生活环境。孩子们会非常有兴趣了解家庭寄宿并且学习他们的文化，他们可以在这里结交新朋友，讲英语同时了解英国的生活方式。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              寄宿家庭是监护人吗？
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <div class="card-body">
            不，Study Links 仍然是监护人，我们会安排您的孩子住在寄宿家庭，Study
            Links 将与他们保持联系以确保一切顺利。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Study Links 将如何为我选择寄宿家庭？
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div class="card-body">
            当您注册 Study Links
            时，我们会要求您发送有关您孩子和任何寄宿家庭偏好的一些详细信息。我们会仔细研究可以提供接待服务的家庭，看看哪一个家庭最合适。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              学生能一直住在同一个家庭吗？
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion"
        >
          <div class="card-body">
            只要有可能，我们会尽量安排学生住在同一个家庭，只要他们在那里住的开心。有时，当您的孩子需要住宿时，这个寄宿家庭却无法提供寄宿服务，因此我们可能需要在这时候将他们安置在另一个合适的家庭中。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFive">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              我们什么时候需要预订寄宿家庭？
            </button>
          </h5>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordion"
        >
          <div class="card-body">
            您越早让我们知道您的孩子需要寄宿家庭，他们就越有可能找到合适的寄宿家庭。如果他们全年都需要寄宿家庭，您可以提前通知我们，以便提前预订。否则，我们需要在学期第一周前知道他们需要家庭寄宿。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSix">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              我的孩子可以在周末和假期住在其他地方吗？
            </button>
          </h5>
        </div>
        <div
          id="collapseSix"
          class="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
              Study Links
              寄宿家庭是您孩子在校外的最佳住宿选择，因为我们已经仔细核查过这些寄宿家庭并且知道它们是安全和舒适的。{" "}
            </div>
            <br />
            <div>
              如果他们希望另外安排，我们需要得到您的书面许可，并提供负责人的详细信息。Study
              Links 仍会在紧急情况下采取行动。
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSeven">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              寄宿家庭会提供什么？
            </button>
          </h5>
        </div>
        <div
          id="collapseSeven"
          class="collapse"
          aria-labelledby="headingSeven"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
              我们的寄宿家庭各不相同，但至少会为他们提供一个干净、舒适的居住环境、一日三餐和属于自己的书房。{" "}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingEight">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              家庭寄宿取消截止日期/费用是多少？
            </button>
          </h5>
        </div>
        <div
          id="collapseEight"
          class="collapse"
          aria-labelledby="headingEight"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>这取决于您选择的服务。 请参阅您的合同协议。</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingNine">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              家庭寄宿的费用是多少？
            </button>
          </h5>
        </div>
        <div
          id="collapseNine"
          class="collapse"
          aria-labelledby="headingNine"
          data-parent="#accordion"
        >
          <div class="card-body">
            这取决于您选择的位置、家庭寄宿服务和监护服务。 请参阅您的合同协议。
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomestayFAQAccordion
